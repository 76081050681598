import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { map, retry, switchMap, tap, BehaviorSubject, Observable } from 'rxjs';
import { ReferenceData } from './shared/models/reference-data.model';
import { DateTimeUtil, UrlUtil } from './shared/utils';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Lab, LabsService, LocaleService } from '@lims-common-ux/lux';
import { AppStateService } from './app-state.service';
import { IndexResource } from './interfaces/indexResource.interface';
import { INDEX_RESOURCE, LABS } from './application-init.service';

const RETRY_COUNT = 2;

@Injectable()
export class AppService {
  // headers intended to be applied to all AppService requests
  headers = new HttpHeaders();

  // publishes when core application data is returned
  referenceDataSubject: BehaviorSubject<ReferenceData> = new BehaviorSubject<ReferenceData>(null);
  // publishes when the lab has changed
  // once lab selection is fully removed this stream will be useless
  labSubject: BehaviorSubject<Lab> = new BehaviorSubject<Lab>(null);
  // the full set of all labs objects given from initial application call
  availableLabs: Lab[] = [];

  private _showLoadingSpinner: BehaviorSubject<boolean> = new BehaviorSubject(true);
  showLoadingSpinner: Observable<boolean> = this._showLoadingSpinner.asObservable();

  constructor(
    @Inject(INDEX_RESOURCE) private indexResource: IndexResource,
    @Inject(LABS) private labs: Lab[],
    private appState: AppStateService,
    public http: HttpClient,
    public translate: TranslateService,
    private router: Router,
    private labsService: LabsService,
    private localeService: LocaleService
  ) {}

  set loadingSpinnerVisible(value) {
    this._showLoadingSpinner.next(value);
  }

  get loadingSpinnerVisible() {
    return this._showLoadingSpinner.value;
  }

  setupLab(labId: string): Observable<ReferenceData> {
    if (!this.labs || !this.labs.length) {
      throw Error('Labs not loaded.');
    }
    let newLab = null;
    this.labs.forEach((lab) => {
      if (lab.id === labId.toUpperCase()) {
        newLab = lab;
      }
    });
    if (newLab == null) {
      throw Error('No lab found for given id: ' + labId);
    }

    this.labsService.currentLab = newLab;

    return this.translate.getTranslation(this.localeService.getSupportedLanguageLocale()).pipe(
      switchMap(() => {
        DateTimeUtil.setDefaultZone(newLab.timeZone);

        return this.loadReferenceData(this.localeService.selectedLocale).pipe(
          tap(() => {
            // only signal that a lab as changed after we get all the reference data loaded.
            this.labSubject.next(this.labsService.currentLab);
          })
        );
      })
    );
  }

  loadReferenceData(locale: string) {
    let url = this.indexResource._links.orderEntry.href;
    if (this.indexResource._links.orderEntry.templated) {
      const serviceCloudEnvs = UrlUtil.getQueryStringParams('serviceCloudEnv');
      url = UrlUtil.interpolateUrl(
        this.indexResource._links.orderEntry.href,
        serviceCloudEnvs ? { serviceCloudEnv: serviceCloudEnvs[0] } : {}
      );
    }

    return this.http
      .get<ReferenceData>(url, {
        headers: this.headers,
      })
      .pipe(
        retry(RETRY_COUNT),
        tap((referenceData) => {
          this.appState.referenceData = new ReferenceData(referenceData);
          this.referenceDataSubject.next(this.appState.referenceData);
        }),
        switchMap(() => {
          if (!locale) {
            throw new Error('No Locale Set');
          }
          // We need to have our reference data before setting up our localized form
          return this.setUpLocalizedForm(locale);
        }),
        map(() => {
          // make sure we return the reference data, and not the translate-service results
          return this.appState.referenceData;
        })
      );
  }

  private setUpLocalizedForm(language: string): Observable<any> {
    return this.translate.use(language);
  }

  constructLabUrl(labId: string, parentRoute?: ActivatedRouteSnapshot, parentUrl = '') {
    let url = parentUrl;
    const route = parentRoute || this.router.routerState.snapshot.root;

    if (route.url[0] && route.url[0].path === 'order-entry') {
      url += `/order-entry/${labId}`;
    } else if (route.url[0] && route.url[0].path === 'accessions') {
      url += `/accessions/${route.params.accessionId}`;
    } else if (route.url.length) {
      url += '/' + route.url.map((urlSegment) => urlSegment.path).join('/');
    }

    return route.firstChild ? this.constructLabUrl(labId, route.firstChild, url) : url;
  }
}
